import { ApolloError } from '@apollo/client';
import { CallById, CreateCallMutation } from '@queries';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AppDispatch, RootState } from '@store';
import { Call, CallInput, CallResponse, RequestStatus } from '@types';
import apolloClient from 'api/apollo-client';

export const createCall = createAsyncThunk<
  any,
  any,
  {
    dispatch: AppDispatch;
    state: RootState;
  }
>(
  'call/CreateCallMutation',
  async (data: CallInput, { dispatch, rejectWithValue }) => {
    if (data) {
      try {
        const response = await apolloClient.mutate<CallResponse>({
          mutation: CreateCallMutation,
          variables: data,
        });
        if (response?.data?.createCall) {
          dispatch(setCurrentCall(response?.data?.createCall));
          dispatch(setCountdownInitData(new Date().getTime() + 122000));
        }
        return response?.data?.createCall;
      } catch (error) {
        const apolloError = error as ApolloError;
        console.log(apolloError);
        rejectWithValue(error);
        throw apolloError;
      }
    }
  },
);

export const getCallByIdThunk = createAsyncThunk(
  'call/CallById',
  async (callId: string) => {
    const response = await apolloClient.query<any>({
      query: CallById,
      variables: { id: callId },
    });
    return response.data.callById;
  },
);

export const callSlice = createSlice({
  name: 'call',
  initialState: {
    call: null as Call | null,
    countdown: 0,
    status: RequestStatus.NoRequested,
  },
  reducers: {
    setCurrentCall: (state, action) => {
      state.call = action.payload;
    },
    resetCall: (state, _) => {
      state.call = null;
    },
    setCountdownInitData: (state, action) => {
      state.countdown = action.payload;
    },
    resetCountdown: (state, _) => {
      state.countdown = 0;
    },
  },
});

export const {
  setCurrentCall,
  resetCall,
  setCountdownInitData,
  resetCountdown,
} = callSlice.actions;

export default callSlice.reducer;

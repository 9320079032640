// eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
import React from 'react';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { ApolloProvider } from '@apollo/client';
import client from './src/api/apollo-client';
import { store, persistor } from './src/store';

export const RootElementWithProviders = ({ element }) => {
  return (
    <Provider store={store}>
      <PersistGate
        loading={null}
        persistor={persistor}>
        <ApolloProvider client={client}>{element}</ApolloProvider>
      </PersistGate>
    </Provider>
  );
};

import { gql } from '@apollo/client';

export const ExpertContactMutation = gql`
  mutation ExpertContact(
    $name: String!
    $lastName: String!
    $phone: String!
    $email: String!
    $skills: String!
    $yearsOfExperience: String!
    $content: String!
  ) {
    sendExpertContactEmail(
      input: {
        name: $name
        lastName: $lastName
        phone: $phone
        email: $email
        skills: $skills
        yearsOfExperience: $yearsOfExperience
        content: $content
      }
    ) {
      message
      status
    }
  }
`;

export const UserContactMutation = gql`
  mutation UserContact(
    $name: String!
    $lastName: String!
    $phone: String!
    $email: String!
    $content: String!
  ) {
    sendUserContactEmail(
      input: {
        name: $name
        lastName: $lastName
        phone: $phone
        email: $email
        content: $content
      }
    ) {
      message
      status
    }
  }
`;

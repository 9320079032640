import { gql } from '@apollo/client';

export const CheckoutMutation = gql`
  mutation CheckoutMutation(
    $product_id: ID!
    $tokenize: Boolean!
    $payMethod: PayMethod!
    $applyFirstPurchaseDiscount: Boolean!
  ) {
    checkout(
      product_id: $product_id
      tokenize: $tokenize
      payMethod: $payMethod
      applyFirstPurchaseDiscount: $applyFirstPurchaseDiscount
    ) {
      Ds_SignatureVersion
      Ds_MerchantParameters
      Ds_Signature
      url
    }
  }
`;

export const PayAWithStoredMethodMutation = gql`
  mutation PayAWithStoredMethod(
    $product_id: ID!
    $useStoredPayMethod: ID!
    $applyFirstPurchaseDiscount: Boolean!
  ) {
    checkout(
      product_id: $product_id
      useStoredPayMethod: $useStoredPayMethod
      applyFirstPurchaseDiscount: $applyFirstPurchaseDiscount
    ) {
      Ds_SignatureVersion
      Ds_MerchantParameters
      Ds_Signature
      url
    }
  }
`;

export const DeletePaymentMethodMutation = gql`
  mutation DeletePaymentMethod($card_id: ID!) {
    deletePaymentMethod(id: $card_id) {
      id
    }
  }
`;

import { Review } from '@types';

export enum ExpertStatus {
  AVAILABLE = 'available',
  BUSY = 'busy',
  OFFLINE = 'offline',
  REQUESTED = 'requested',
}

export interface Characteristic {
  id: number;
  name: string;
  color: string;
}

export interface Schedule {
  dayName: string;
  start: string;
  end: string;
}

export interface Tool {
  id: number;
  name: string;
  imageUrl: string;
}

export interface Speciality {
  id: number;
  name: string;
  imageUrl: string;
}

export interface ProcessCall {
  startTime: string;
  requestedDuration: number;
}

export interface Expert {
  id: number;
  imageUrl: string;
  nick: string;
  description: string;
  callServiceCode: string;
  availability: ExpertStatus;
  inProcessCall: ProcessCall | null;
  characteristics: Characteristic[];
  schedules: Schedule[];
  tools: Tool[];
  specialities: Speciality[];
  reviews: Review[];
}

export interface ExpertsResponse {
  experts: {
    data: Expert[];
  };
}

export interface ExpertReviewsResponse {
  expert: {
    reviews: Review[];
  };
}

export interface ExpertUpdateResponse {
  expert: Expert;
}

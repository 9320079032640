import {
  createEntityAdapter,
  createAsyncThunk,
  createSlice,
} from '@reduxjs/toolkit';
import { GetProducts } from '@queries';
import { Product, ProductsResponse, RequestStatus } from '@types';
import apolloClient from 'api/apollo-client';
import { RootState } from '@store';

export const productsAdapter = createEntityAdapter<Product>();

export const productsThunk = createAsyncThunk('api/products', async _ => {
  const response = await apolloClient.query<ProductsResponse>({
    query: GetProducts,
  });
  return response.data.products;
});

const selectProducts = (state: RootState) => state.products;
export const {
  selectAll: selectAllProducts,
  selectEntities: selectEntitiesProducts,
} = productsAdapter.getSelectors(selectProducts);

export const productsSlice = createSlice({
  name: 'products',
  initialState: {
    ...productsAdapter.getInitialState(),
    status: RequestStatus.NoRequested,
  },
  reducers: {
    emptyProducts: (state, _) => {
      productsAdapter.removeAll(state);
    },
  },
  extraReducers(builder) {
    builder.addCase(productsThunk.pending, (state, _) => {
      state.status = RequestStatus.Pending;
    });
    builder.addCase(productsThunk.fulfilled, (state, action) => {
      productsAdapter.setAll(state, action.payload);
      state.status = RequestStatus.Fulfilled;
    });
    builder.addCase(productsThunk.rejected, (state, _) => {
      state.status = RequestStatus.Rejected;
    });
  },
});

export const { emptyProducts } = productsSlice.actions;

export default productsSlice.reducer;

import {
  ApolloClient,
  ApolloLink,
  DefaultOptions,
  HttpLink,
  InMemoryCache,
  from,
} from '@apollo/client';

const httpLink = new HttpLink({
  uri:
    process.env.GATSBY_GRAPHQL_URL || `https://portal.arly-online.com/graphql`,
});

const authMiddleware = new ApolloLink((operation, forward) => {
  if (
    typeof window !== 'undefined' &&
    ![
      'LoginMutation',
      'RegisterMutation',
      'ForgotPasswordMutation',
      'UpdateForgottenPassword',
      'VerifyEmail',
    ].includes(operation.operationName)
  ) {
    const root = localStorage.getItem('persist:root');
    if (root) {
      const user = JSON.parse(root).user;
      if (user) {
        const authorization = `Bearer ${JSON.parse(user).access_token}`;
        if (authorization) {
          operation.setContext(({ headers = {} }) => ({
            headers: {
              ...headers,
              authorization,
            },
          }));
        }
      }
    }
  }
  return forward(operation);
});

const defaultOptions: DefaultOptions = {
  watchQuery: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'ignore',
  },
  query: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'all',
  },
};

const client = new ApolloClient({
  link: from([authMiddleware, httpLink]),
  cache: new InMemoryCache(),
  defaultOptions: defaultOptions,
});

export default client;

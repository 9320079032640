import './static/fonts/inter/stylesheet.css';
import './static/fonts/georgia/stylesheet.css';

import './src/styles/global.scss';

import { RootElementWithProviders } from './wrap-with-provider';

export const shouldUpdateScroll = ({
  routerProps: { location },
  getSavedScrollPosition,
}) => {
  // const { pathname } = location;
  const currentPosition = getSavedScrollPosition(location);
  // const scrollToTopRoutes = [`/id/`, `/en/`];
  // if (scrollToTopRoutes.indexOf(pathname) !== -1) {
  window.scrollTo(currentPosition * 0, 0);
  // }
  return false;
};

export const wrapRootElement = RootElementWithProviders;

import { Expert, Review } from '@types';

export enum CallStatus {
  Waiting = 'waiting', // Esperando la llamada del cliente
  InProcess = 'in_process', // Llamada en curso
  Finished = 'finished', // Llamada finalizada
  OutOfTime = 'out_of_time', // Cliente no llamó a tiempo
  NotAnswered = 'not_answered', // El Experto no contestó la llamada
}

export interface Call {
  id?: string;
  apiPhone: string;
  userPhone: string;
  requestedDuration: number;
  expert?: Expert;
  duration?: number;
  status?: string;
  startTime?: string;
  endTime?: string;
  createdAt?: string;
  updatedAt?: string;
  review?: Review;
}

export interface CallInput {
  expertId: number;
  requestedDuration: number;
  userPhoneId: string;
}

export interface CallResponse {
  createCall: {
    apiPhone: string;
    userPhone: string;
    requestedDuration: number;
    expert: {
      id: string;
      nick: string;
      availability: string;
    };
  };
}

export interface Review {
  rating: number;
  text: string;
  status?: string;
  date?: string;
  expertId?: number;
  userName?: string;
}

export interface ReviewInput {
  expertId: number;
  rating: number;
  text: string;
}

export interface EditReviewInput {
  id: number;
  rating: number;
  text: string;
}

export enum ReviewStatus {
  PENDING = 'pending',
  APPROVED = 'approved',
  REJECTED = 'rejected',
}

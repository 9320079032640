import { gql } from '@apollo/client';

export const GetExperts = gql`
  query GetExperts {
    experts {
      data {
        id
        imageUrl
        nick
        description
        callServiceCode
        availability
        inProcessCall {
          startTime
          requestedDuration
        }
        characteristics {
          id
          name
          color
        }
        schedules {
          dayName
          start
          end
        }
        tools {
          id
          name
          imageUrl
        }
        specialities {
          id
          name
          imageUrl
        }
        reviews {
          rating
          text
          date
          userName
        }
      }
    }
  }
`;

export const UpdateExpert = gql`
  query UpdateExpert($id: ID!) {
    expert(id: $id) {
      id
      imageUrl
      nick
      description
      callServiceCode
      availability
      inProcessCall {
        startTime
        requestedDuration
      }
      characteristics {
        id
        name
        color
      }
      schedules {
        dayName
        start
        end
      }
      tools {
        id
        name
        imageUrl
      }
      specialities {
        id
        name
        imageUrl
      }
      reviews {
        rating
        text
        date
        userName
      }
    }
  }
`;

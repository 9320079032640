import { CheckoutMutation, PayAWithStoredMethodMutation } from '@queries';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AppDispatch, RootState } from '@store';
import { Cart, CheckoutResponse, RequestStatus } from '@types';
import apolloClient from 'api/apollo-client';

export const createPayment = createAsyncThunk<
  any,
  any,
  {
    dispatch: AppDispatch;
    state: RootState;
  }
>('cart/CheckoutMutation', async (data: any, { rejectWithValue, getState }) => {
  const { cart } = getState().cart;
  if (cart) {
    try {
      const response = await apolloClient.mutate<CheckoutResponse>({
        mutation: CheckoutMutation,
        variables: {
          product_id: cart.id,
          tokenize: data.tokenize || false,
          payMethod: data.payMethod || 'Card',
          applyFirstPurchaseDiscount: data.applyFirstPurchaseDiscount || false,
        },
      });
      return response?.data?.checkout;
    } catch (error) {
      console.log(error);
      rejectWithValue(error);
    }
  }
});

export const createPaymentWithStoredCard = createAsyncThunk<
  any,
  any,
  {
    dispatch: AppDispatch;
    state: RootState;
  }
>(
  'cart/PayAWithStoredMethodMutation',
  async (data: any, { rejectWithValue, getState }) => {
    const { cart } = getState().cart;
    if (cart) {
      try {
        const response = await apolloClient.mutate<CheckoutResponse>({
          mutation: PayAWithStoredMethodMutation,
          variables: {
            product_id: cart.id,
            useStoredPayMethod: data.cardId,
            applyFirstPurchaseDiscount:
              data.applyFirstPurchaseDiscount || false,
          },
        });
        return response?.data?.checkout;
      } catch (error) {
        console.log(error);
        rejectWithValue(error);
      }
    }
  },
);

export const cartSlice = createSlice({
  name: 'cart',
  initialState: {
    cart: null as Cart | null,
    status: RequestStatus.NoRequested,
  },
  reducers: {
    addCartItem: (state, action) => {
      state.cart = action.payload;
      // state.cart.push(action.payload);
    },
    removeCartItem: (state, _) => {
      state.cart = null;
      // state.cart.splice(
      //   state.cart.findIndex(item => item.id === action.payload.id),
      //   1,
      // );
    },
    emptyCart: (state, _) => {
      state.cart = null; // [];
    },
  },
});

export const { addCartItem, removeCartItem, emptyCart } = cartSlice.actions;

export default cartSlice.reducer;

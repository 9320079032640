import { combineReducers, configureStore } from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage';
import {
  FLUSH,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
  REHYDRATE,
  persistReducer,
  persistStore,
} from 'redux-persist';
import { callSlice } from './call/callSlice';
import { cartSlice } from './cart/cartSlice';
import { expertsSlice } from './experts/expertsSlice';
import { productsSlice } from './products/productsSlice';
import { userSlice } from './user/userSlice';

const reducer = combineReducers({
  call: callSlice.reducer,
  cart: cartSlice.reducer,
  experts: expertsSlice.reducer,
  products: productsSlice.reducer,
  user: userSlice.reducer,
});

const persistConfig = {
  key: 'root',
  storage,
};

const persistedReducer = persistReducer(persistConfig, reducer);

const store = configureStore({
  reducer: persistedReducer,
  devTools: true,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

const persistor = persistStore(store);
export { store, persistor };

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;

export * from './call/callSlice';
export * from './cart/cartSlice';
export * from './experts/expertsSlice';
export * from './products/productsSlice';
export * from './user/userSlice';

import { ApolloError } from '@apollo/client';
import {
  AddUserPhoneMutation,
  CreateReviewForExpertMutation,
  DeletePaymentMethodMutation,
  EditReviewMutation,
  GetMe,
  UpdateUserMutation,
} from '@queries';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AppDispatch, RootState } from '@store';
import {
  User,
  RequestStatus,
  UserPhoneResponse,
  UpdateUserResponse,
  ReviewInput,
  EditReviewInput,
} from '@types';
import apolloClient from 'api/apollo-client';

export const getMe = createAsyncThunk<
  any,
  any,
  {
    dispatch: AppDispatch;
    state: RootState;
  }
>('user/getMe', async (_: any, { dispatch, rejectWithValue }) => {
  try {
    const response = await apolloClient.query<{
      me: User;
    }>({ query: GetMe, fetchPolicy: 'no-cache' });
    dispatch(setUser(response.data.me));
  } catch (error) {
    rejectWithValue(error);
  }
});

export const addPhone = createAsyncThunk<
  any,
  any,
  {
    dispatch: AppDispatch;
    state: RootState;
  }
>('user/addUserPhone', async (phone: any, { rejectWithValue }) => {
  if (phone) {
    try {
      const response = await apolloClient.mutate<UserPhoneResponse>({
        mutation: AddUserPhoneMutation,
        variables: {
          phone,
        },
      });
      return response?.data?.addUserPhone;
    } catch (error: any) {
      const apolloError = error as ApolloError;
      console.log(apolloError);
      rejectWithValue(error);
    }
  }
});

export const updateUser = createAsyncThunk<
  any,
  any,
  {
    dispatch: AppDispatch;
    state: RootState;
  }
>('user/updateUser', async (data: any, { dispatch, rejectWithValue }) => {
  if (data) {
    try {
      const response = await apolloClient.mutate<UpdateUserResponse>({
        mutation: UpdateUserMutation,
        variables: data,
      });
      if (response?.data?.updateUser) {
        dispatch(setUser(response?.data?.updateUser));
      }
      return response?.data?.updateUser;
    } catch (error: any) {
      const apolloError = error as ApolloError;
      console.log(apolloError);
      rejectWithValue(error);
    }
  }
});

export const deleteCard = createAsyncThunk<
  any,
  any,
  {
    dispatch: AppDispatch;
    state: RootState;
  }
>('user/deleteCard', async (cardId: any, { rejectWithValue }) => {
  if (cardId) {
    try {
      const response = await apolloClient.mutate<any>({
        mutation: DeletePaymentMethodMutation,
        variables: {
          card_id: cardId,
        },
      });
      return response?.data;
    } catch (error: any) {
      const apolloError = error as ApolloError;
      console.log(apolloError);
      rejectWithValue(error);
    }
  }
});

export const createReview = createAsyncThunk<
  any,
  any,
  {
    dispatch: AppDispatch;
    state: RootState;
  }
>('user/createReview', async (data: ReviewInput, { rejectWithValue }) => {
  if (data) {
    try {
      const response = await apolloClient.mutate<any>({
        mutation: CreateReviewForExpertMutation,
        variables: data,
      });
      return response?.data;
    } catch (error: any) {
      const apolloError = error as ApolloError;
      console.log(apolloError);
      rejectWithValue(error);
    }
  }
});

export const editReview = createAsyncThunk<
  any,
  any,
  {
    dispatch: AppDispatch;
    state: RootState;
  }
>('user/editReview', async (data: EditReviewInput, { rejectWithValue }) => {
  if (data) {
    try {
      const response = await apolloClient.mutate<any>({
        mutation: EditReviewMutation,
        variables: data,
      });
      return response?.data;
    } catch (error: any) {
      const apolloError = error as ApolloError;
      console.log(apolloError);
      rejectWithValue(error);
    }
  }
});

export const userSlice = createSlice({
  name: 'user',
  initialState: {
    user: null as User | null,
    access_token: '',
    refresh_token: '',
    status: RequestStatus.NoRequested,
  },
  reducers: {
    setUser: (state, action) => {
      state.user = action.payload;
    },
    setTokens: (state, action) => {
      state.access_token = action.payload.access_token;
      state.refresh_token = action.payload.refresh_token;
    },
    logoutUser: (state, _) => {
      state.user = null;
      state.access_token = '';
      state.refresh_token = '';
    },
  },
});

export const { setUser, setTokens, logoutUser } = userSlice.actions;

export default userSlice.reducer;

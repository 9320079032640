import { gql } from '@apollo/client';

export const GetProducts = gql`
  query GetProducts {
    products {
      id
      imageUrl
      isSpecialRate
      price
      priceByMinute
      minutes
    }
  }
`;

import { gql } from '@apollo/client';

export const LoginMutation = gql`
  mutation LoginMutation($username: String!, $password: String!) {
    login(input: { username: $username, password: $password }) {
      access_token
      expires_in
      refresh_token
      token_type
      user {
        email
        id
        name
      }
    }
  }
`;

export const RegisterMutation = gql`
  mutation RegisterMutation(
    $first_name: String!
    $last_name: String!
    $email: String!
    $password: String!
    $passwordConfirmation: String!
  ) {
    register(
      input: {
        first_name: $first_name
        last_name: $last_name
        email: $email
        password: $password
        password_confirmation: $passwordConfirmation
      }
    ) {
      status
      tokens {
        access_token
        expires_in
        refresh_token
        token_type
        user {
          email
          id
          name
        }
      }
    }
  }
`;

export const ForgotPasswordMutation = gql`
  mutation ForgotPasswordMutation($email: String!) {
    forgotPassword(input: { email: $email }) {
      message
      status
    }
  }
`;

export const UpdateForgottenPasswordMutation = gql`
  mutation UpdateForgottenPassword(
    $email: String!
    $token: String!
    $password: String!
    $passwordConfirmation: String!
  ) {
    updateForgottenPassword(
      input: {
        email: $email
        token: $token
        password: $password
        password_confirmation: $passwordConfirmation
      }
    ) {
      message
      status
    }
  }
`;

export const VerifyEmail = gql`
  mutation VerifyEmail($token: String!) {
    verifyEmail(input: { token: $token }) {
      access_token
      expires_in
      refresh_token
      token_type
      user {
        email
        id
        name
      }
    }
  }
`;

export const GetMe = gql`
  query GetMe {
    me {
      id
      name
      first_name
      last_name
      email
      country
      timezone
      acceptsCommunication
      newsletterSubscribed
      firstPurchaseDiscountPercentage
      availableMinutes
      phones {
        id
        order
        country_code
        subscriber_number
        e164Format
      }
      processedOrders {
        id
        price
        minutes
        orderStatus
        description
        created_at
      }
      paymentMethods {
        id
        cardBrandName
        card_number
        expires_date
      }
      calls(first: 100, page: 1) {
        paginatorInfo {
          count
          currentPage
          firstItem
          hasMorePages
          lastItem
          lastPage
          perPage
        }
        data {
          id
          apiPhone
          userPhone
          requestedDuration
          duration
          status
          startTime
          endTime
          createdAt
          updatedAt
          review {
            rating
            text
            status
            date
            userName
          }
          expert {
            id
            imageUrl
            nick
            availability
            characteristics {
              id
              name
              color
            }
          }
        }
      }
    }
  }
`;

export const AddUserPhoneMutation = gql`
  mutation AddUserPhoneMutation($phone: String!) {
    addUserPhone(phone: $phone) {
      id
      phone
    }
  }
`;

export const UpdateUserMutation = gql`
  mutation UpdateUserMutation(
    $first_name: String!
    $last_name: String!
    $email: String
    $country: String
    $timezone: String
    $newsletterSubscribed: Boolean
    $acceptsCommunication: Boolean
    $phones: [AddPhoneInput]
  ) {
    updateUser(
      input: {
        first_name: $first_name
        last_name: $last_name
        email: $email
        country: $country
        timezone: $timezone
        newsletterSubscribed: $newsletterSubscribed
        acceptsCommunication: $acceptsCommunication
        phones: $phones
      }
    ) {
      id
      name
      first_name
      last_name
      email
      country
      timezone
      acceptsCommunication
      newsletterSubscribed
      firstPurchaseDiscountPercentage
      availableMinutes
      phones {
        id
        order
        country_code
        subscriber_number
        e164Format
      }
      processedOrders {
        id
        price
        minutes
        orderStatus
        description
        created_at
      }
      paymentMethods {
        id
        cardBrandName
        card_number
        expires_date
      }
      calls(first: 100, page: 1) {
        paginatorInfo {
          count
          currentPage
          firstItem
          hasMorePages
          lastItem
          lastPage
          perPage
        }
        data {
          id
          apiPhone
          userPhone
          requestedDuration
          duration
          status
          startTime
          endTime
          createdAt
          updatedAt
          review {
            rating
            text
            status
            date
            userName
          }
          expert {
            id
            imageUrl
            nick
            availability
            characteristics {
              id
              name
              color
            }
          }
        }
      }
    }
  }
`;

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-checkout-tsx": () => import("./../../../src/pages/checkout.tsx" /* webpackChunkName: "component---src-pages-checkout-tsx" */),
  "component---src-pages-como-funciona-tsx": () => import("./../../../src/pages/como-funciona.tsx" /* webpackChunkName: "component---src-pages-como-funciona-tsx" */),
  "component---src-pages-comprar-minutos-tsx": () => import("./../../../src/pages/comprar-minutos.tsx" /* webpackChunkName: "component---src-pages-comprar-minutos-tsx" */),
  "component---src-pages-cookies-tsx": () => import("./../../../src/pages/cookies.tsx" /* webpackChunkName: "component---src-pages-cookies-tsx" */),
  "component---src-pages-forgot-password-tsx": () => import("./../../../src/pages/forgot-password.tsx" /* webpackChunkName: "component---src-pages-forgot-password-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-llamar-tsx": () => import("./../../../src/pages/llamar.tsx" /* webpackChunkName: "component---src-pages-llamar-tsx" */),
  "component---src-pages-login-tsx": () => import("./../../../src/pages/login.tsx" /* webpackChunkName: "component---src-pages-login-tsx" */),
  "component---src-pages-nuestros-expertos-tsx": () => import("./../../../src/pages/nuestros-expertos.tsx" /* webpackChunkName: "component---src-pages-nuestros-expertos-tsx" */),
  "component---src-pages-paymentko-tsx": () => import("./../../../src/pages/paymentko.tsx" /* webpackChunkName: "component---src-pages-paymentko-tsx" */),
  "component---src-pages-paymentok-tsx": () => import("./../../../src/pages/paymentok.tsx" /* webpackChunkName: "component---src-pages-paymentok-tsx" */),
  "component---src-pages-privacidad-tsx": () => import("./../../../src/pages/privacidad.tsx" /* webpackChunkName: "component---src-pages-privacidad-tsx" */),
  "component---src-pages-profile-tsx": () => import("./../../../src/pages/profile.tsx" /* webpackChunkName: "component---src-pages-profile-tsx" */),
  "component---src-pages-registro-tsx": () => import("./../../../src/pages/registro.tsx" /* webpackChunkName: "component---src-pages-registro-tsx" */),
  "component---src-pages-reset-password-tsx": () => import("./../../../src/pages/reset-password/[...].tsx" /* webpackChunkName: "component---src-pages-reset-password-tsx" */),
  "component---src-pages-sobre-nosotros-tsx": () => import("./../../../src/pages/sobre-nosotros.tsx" /* webpackChunkName: "component---src-pages-sobre-nosotros-tsx" */),
  "component---src-pages-solicitar-tsx": () => import("./../../../src/pages/solicitar.tsx" /* webpackChunkName: "component---src-pages-solicitar-tsx" */),
  "component---src-pages-terminos-tsx": () => import("./../../../src/pages/terminos.tsx" /* webpackChunkName: "component---src-pages-terminos-tsx" */),
  "component---src-pages-unete-tsx": () => import("./../../../src/pages/unete.tsx" /* webpackChunkName: "component---src-pages-unete-tsx" */),
  "component---src-templates-expert-template-tsx": () => import("./../../../src/templates/ExpertTemplate.tsx" /* webpackChunkName: "component---src-templates-expert-template-tsx" */),
  "slice---src-components-footer-tsx": () => import("./../../../src/components/Footer.tsx" /* webpackChunkName: "slice---src-components-footer-tsx" */),
  "slice---src-components-header-tsx": () => import("./../../../src/components/Header.tsx" /* webpackChunkName: "slice---src-components-header-tsx" */)
}


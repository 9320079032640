module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Arly","short_name":"Arly","start_url":"/","background_color":"#ffffff","theme_color":"#B38DEA","display":"minimal-ui","icon":"static/favicon.png","crossOrigin":"use-credentials","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","include_favicon":true,"cacheDigest":"ac633e1044f2e9af21960d80e85455ab"},
    },{
      plugin: require('../node_modules/gatsby-plugin-react-i18next/gatsby-browser.js'),
      options: {"plugins":[],"localeJsonSourceName":"locale","languages":["es"],"defaultLanguage":"es","fallbackLanguage":"es","siteUrl":"https://arly-online.com","redirect":false,"trailingSlash":"always","i18nextOptions":{"defaultNS":"common","interpolation":{"escapeValue":false},"keySeparator":".","nsSeparator":":"}},
    },{
      plugin: require('../node_modules/gatsby-plugin-gdpr-cookies/gatsby-browser.js'),
      options: {"plugins":[],"googleAnalytics":{"trackingId":"","cookieName":"gdpr-google-analytics","anonymize":true},"googleTagManager":{"trackingId":"","cookieName":"gdpr-google-tagmanager","dataLayerName":"dataLayer"},"facebookPixel":{"pixelId":"","cookieName":"gdpr-facebook-pixel"},"environments":["production","development"]},
    },{
      plugin: require('../gatsby-browser.ts'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]

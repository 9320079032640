import { gql } from '@apollo/client';

export const CreateCallMutation = gql`
  mutation CreateCallMutation(
    $expertId: ID!
    $requestedDuration: Int!
    $userPhoneId: ID!
  ) {
    createCall(
      input: {
        expertId: $expertId
        requestedDuration: $requestedDuration
        userPhoneId: $userPhoneId
      }
    ) {
      id
      apiPhone
      userPhone
      requestedDuration
      expert {
        id
        nick
        availability
      }
    }
  }
`;

export const CallById = gql`
  query CallById($id: ID!) {
    callById(id: $id) {
      apiPhone
      duration
      status
      startTime
    }
  }
`;

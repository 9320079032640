import { gql } from '@apollo/client';

export const CreateReviewForExpertMutation = gql`
  mutation CreateReviewMutation($callId: ID!, $rating: Int!, $text: String) {
    createReview(callId: $callId, rating: $rating, text: $text) {
      rating
      text
    }
  }
`;

export const EditReviewMutation = gql`
  mutation EditReviewMutation($id: ID!, $rating: Int!, $text: String) {
    editReview(id: $id, rating: $rating, text: $text) {
      rating
      text
    }
  }
`;

export const GetApprovedReviewsForExpert = gql`
  query GetApprovedReviewsForExpert($id: ID!) {
    expert(id: $id) {
      reviews {
        rating
        text
        date
        userName
      }
    }
  }
`;

export const GetReviewsOfCurrentUser = gql`
  query GetReviewsOfCurrentUser {
    me {
      reviews {
        rating
        text
        status
        date
        expertId
        userName
      }
    }
  }
`;
